<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        <a :href="$router.resolve({name: 'Cookies'}).href">Cookie Policy</a>
        • <a :href="$router.resolve({name: 'Privacy'}).href">Privacy</a>
        • <a :href="$router.resolve({name: 'Terms'}).href">Terms and Conditions</a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  data: () => ({})
}
</script>
