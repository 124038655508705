<template>
  <div>
      <Navbar style="min-height: 45px;"/>
      <router-view style="min-height: calc(100vh - 111px)" class="pb-5"/>
      <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
export default {
  components: {
    Navbar, Footer
  }
}
</script>
