<template>
  <b-navbar>
        <template #brand>
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <span id="logo" class="is-vcentered">Clappy</span>
            </b-navbar-item>
        </template>
        <template #start>
            <b-navbar-item v-for="({ name }, i) in computedRoutes" v-bind:key="i" tag="router-link" :to="{ name }">
                <span class="mt-2">{{ name }}</span>
            </b-navbar-item>
        </template>

        <template #end>
            <b-navbar-item tag="div">
                <div class="buttons">
                    <router-link v-if="!$store.state.user.user.id" class="button is-primary" :to="{ name: 'IdAuth' }">
                        Log in
                    </router-link>
                    <b-dropdown v-else aria-role="list" position="is-bottom-left">
                      <template #trigger>
                        <img style="border-radius:20%;min-height:40px;" :src="`https://cdn.discordapp.com/avatars/${$store.state.user.user.id}/${$store.state.user.user.avatar}`" />
                      </template>

                      <b-dropdown-item aria-role="listitem" @click="logout">Log Out</b-dropdown-item>
                    </b-dropdown>
                </div>
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>
import { hasPermission } from '@/utils/permissions'
export default {
  data: () => ({
    routes: [{ name: 'Items' }, { name: 'Boosts' }, { name: 'Leaderboard' }, { name: 'Users', permission: 'users.list' }, { name: 'Guild', permission: 'guild.view' }, { name: 'Roles', permission: 'guild.update.roles' }, { name: 'VIP' }, { name: 'Thank You' }, { name: 'Support' }]
  }),
  computed: {
    computedRoutes () {
      return this.routes.filter(r => r.permission ? hasPermission(r.permission) : true)
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('user/logout').then(() => {
        this.$router.push('/')
        window.location.reload()
      })
    }
  }
}
</script>

<style lang="sass" scoped>
@import url('https://fonts.googleapis.com/css2?family=Sriracha&display=swap');
#logo
  font-family: 'Sriracha', arial
  font-size: 20px
</style>>
